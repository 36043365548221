import { toast } from 'react-toastify'
import * as Cookie from 'utils/cookie';
import moment from 'moment';

export function toastPop({ message = '', type = 'success', autoClose = 2000 }) {
    message && toast(message, { type, autoClose })
}

export function getActivePage(path) {
    // const active = path.split('/')
    // return active[1]
    return path
}

Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
}

export function arrayRemove(arr, value, type) {

    return arr.filter(function (ele) {
        return ele[type] != value;
    });
}
export function removeInvalidURL(value) {
    const cleanURL = value.toLowerCase().replace(/[\s\;\/\?\:\@\=\&\"\>\<\#\%\{\}\|\\\~\[\]\`\^\']/g, '-')
    return cleanURL;
}
export function dashSpecChar(value) { // without space
    const cleanURL = value.toLowerCase().replace(/[\;\/\?\:\@\=\&\"\>\<\#\%\{\}\|\\\~\[\]\`\^\']/g, '-')
    return cleanURL;
}
export function numberValidator(value) {
    if (value === 'e' || value === 'E' || value === '.' || value === '+' || value === '-') {
        return true
    } else {
        return false
    }
}
export function preventSpecChar(str) { // Remove specific char that related to query
    var pattern = /[\;\/\?\:\@\=\&\"\>\<\#\%\{\}\|\\\~\[\]\`\^\']/g
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}
export function specificChar(str) {
    var pattern = /[\s\;\/\?\:\@\=\&\"\>\<\#\%\{\}\|\\\~\[\]\`\^\']/g
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export const formatter = (data) => {
    return Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data ? data.toFixed(2) : 0)
}

export const catch_msg = (err, txt) => {
    let error = { message: txt };
    if (err) {
        if (err.message) {
            error = err
        }
    }
    toastPop({ message: error.message ? error.message : 'CONNECTION REFUSED!', type: 'error' })
}

export const forcedLogout = (err) => {
    if (err) {
        let msg = err.message && err.message.toLowerCase()
        if (msg === "invalid token") {
            Cookie.removeUser()
        }
    }
}

export const validateStr = (str) => {
    var pattern = /^[ñA-Za-z _]*[ñA-Za-z ][ñA-Za-z _]*$/
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export const letterValidator = (str) => {
    var pattern = /^[ñA-Za-z _]*[ñA-Za-z ][ñA-Za-z _]*$/
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export const skuValidator = (str) => {
    var pattern = /^([A-Za-z0-9\-]+)*$/
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export const personNameInvalidator = (person_name) => {
    var pattern = /[^ñÑA-Za-z ]+/
    if (pattern.test(person_name)) {
        return true;
    } else {
        return false;
    }
}

export const mobileValidator = (str) => {
    var pattern = /[\+|0-9|\-]/g
    if (pattern.test(str)) {
        return true;
    } else {
        if (str === 'Backspace' || str === 'ArrowLeft' || str === 'ArrowRight') {
            return true
        }
        else {
            return false;
        }
    }
}

export const moment_format = (date, format = 'LLL') => {
    const is_valid = moment(date).isValid()
    return !is_valid ? '' : date ? moment(date).format(format) : ''
}

export const moment_valueOf = (date) => {
    const is_valid = moment(date).isValid()
    return !is_valid ? 0 : date ? moment(date).valueOf() : 0
}

export const smoothScroll = (target) => {
    var scrollContainer = target;
    do { //find scroll container
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop === 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
        if (target === scrollContainer) break;
        targetY += target.offsetTop;
    } while (target = target.offsetParent);

    const scroll = (c, a, b, i) => {
        i++;
        if (i > 30) return;
        c.scrollTop = a + (b - a) / 30 * i;
        setTimeout(() => { scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
}

export function currencyFormat(num) {
    if (typeof num !== 'number') return 0
    return `₱ ${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export const searchSlipNum = (slip, pattern) => {
    if (pattern && pattern.test(slip)) {
        return true;
    } else {
        return false;
    }
}

export function phoneNumberValidator(mobile_number) {
    const regex_valid_contact0 = mobile_number && mobile_number.match(/09(\d{9})/g)
    const regex_valid_contact1 = mobile_number && mobile_number.match(/\+639(\d{9})/g)
    const regex_valid_contact2 = mobile_number && mobile_number.match(/(\d{3})-(\d{4})/g)
    const v1 = !(regex_valid_contact0 !== null && (regex_valid_contact0[0] === mobile_number))
    const v2 = !(regex_valid_contact1 !== null && (regex_valid_contact1[0] === mobile_number))
    const v3 = !(regex_valid_contact2 !== null && (regex_valid_contact2[0] === mobile_number))

    if (mobile_number && v1 && v2 && v3) {
        return { invalid: true, message: 'Enter a valid contact number (mobile should be 09******** / +639********* or telephone should be ***-****).' }
    }
    else {
        return { invalid: false, message: '' }
    }
}

export function emailValidator(email) {
    const regex_valid_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/g
    const testEmail = email && email.match(regex_valid_email)
    const v4 = !(testEmail !== null && testEmail[0] === email)

    if (email && v4) {
        return { invalid: true, message: 'Enter a valid email address.' }
    }
    else {
        return { invalid: false, message: '' }
    }
}

export const orderIdFormat = (id = 0) => {
    if (typeof id !== 'number') return 0
    return parseInt(id).pad(10)
}

export const loopSubCategory = (subcategory = []) => {
    let result = [], result2 = [];
    const filtered = subcategory.filter(d => d.is_active === '1')

    filtered.map(c => {
        const attribute = c.attributes[0]
        const category_name = attribute ? attribute.value : ''
        const sub_category = c['sub-categories']
        result.push({
            'active': c.is_active === '1' ? true : false,
            'id': c.entity_id,
            'name': category_name,
            'value': category_name,
            'url_key': c.url_key,
            'sub_categories': loopSubCategory(sub_category) || []
        })
    })

    // get subcategory to subcategory => in one content
    result.forEach((d) => {
        if (d.sub_categories.length !== 0) {
            for (let sub_category of d.sub_categories)
                result2.push({ ...d, ...sub_category })
        }
        result2.push({ ...d })
    })

    return result2
}

export const preventIdKeys = (e) => {
    return (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === '-' || e.key === '+') && e.preventDefault()
}
export const preventPriceKeys = (e) => {
    return (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') && e.preventDefault()
}

export const preventSpecialChar = (e) => {
    return (e.key === "'" || e.key === '"' || e.key === '\\' || e.key === '`') && e.preventDefault()
}


export const trunc = (text, max) => {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export const trim = (text) => {
    return text.replace(/ +(?= )/g, '').trim()
}

export const hasEmpty = (obj, type) => {
    const compile = []
    for (let [key, value] of Object.entries(obj)) {
        compile.push(JSON.stringify(value))
    }
    return compile.includes(JSON.stringify(type))
}

export function jsonParse(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }

export const betweenTime = (date_from, date_to) => {
    let from = moment(date_from).format("YYYY-MM-DD")
    let to = moment(date_to).format("YYYY-MM-DD")
    let now = moment().format("YYYY-MM-DD")
    return moment(now).isBetween(from, to, null, '[]')
}